<template>
  <v-dialog ref="dialog" v-model="dialog.display" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="picker.hour"
        :disabled="disabled"
        :label="$t('updateCalendarDialog.timePickerLabel')"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-time-picker
      v-if="dialog.display"
      v-model="picker.hour"
      full-width
      format="24hr"
    >
      <v-spacer />
      <v-btn text color="primary" @click="dialog.display = false">
        {{ $t('general.buttons.cancel') }}
      </v-btn>
      <v-btn text color="primary" @click="onSave">
        {{ $t('general.buttons.validate') }}
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: 'UpdateCalendarTimePicker',

  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    milestone: {
      required: true,
    },
  },

  data: () => ({
    dialog: {
      display: false,
    },
    now: new Date(),
    picker: {
      hour: '',
    },
  }),

  watch: {
    milestone: function() {
      this.milestoneUpdated()
    },
  },

  created() {
    this.milestoneUpdated()
  },

  methods: {
    onSave() {
      /*
      const dateString = this.milestone.date.toLocaleDateString(
          navigator.language || 'fr-FR',
          {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          },
      ) */
      const dateString = this.milestone.date.toDateString()

      this.$emit('update:milestone', {
        ...this.milestone,
        date: new Date(`${dateString} ${this.picker.hour}`),
      })
      this.milestoneUpdated()
      this.dialog.display = false
    },
    milestoneUpdated() {
      if (this.milestone) {
        /*
        this.picker.hour = this.milestone.date.toLocaleTimeString(
          navigator.language || 'fr-FR',
          {
            hour: '2-digit',
            minute: '2-digit',
          }
        )
        */
        this.picker.hour = this.milestone.date.toLocaleTimeString('fr-FR', {hour12: false});
      }
    },
  },
}
</script>
